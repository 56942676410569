import React from 'react'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import { locale } from '../../../src/common/i18n'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingQuadEs = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
          El Quad y los vehículos ATV están diseñado para una conducción
          puramente Off-Road. Su uso está destinado principalmente a
          competiciones deportivas de toda clase y recorrer terrenos arenosos e
          irregulares.
        </p>
        <p className={styles.main_description}>
          La demanda de neumáticos de Quad en las Islas Canarias ha ido
          creciendo durante los últimos años con la proliferación de las
          excursiones guiadas en esta clase de vehículos que son ideales para
          adentrarse en la naturaleza.
        </p>
        <section className={styles.section}>
          <h2>¿Cómo elegir los mejores neumáticos para mi Quad?</h2>
          <p>
            El aspecto más importante para escoger neumáticos de Quad se
            encuentra en la dureza de sus gomas. En un lugar como Canarias donde
            el terreno es seco, con arena y zonas pedregosas la mejor opción es
            elegir neumáticos que usan un compuesto más bien duro. Para terrenos
            blandos y húmedos, en cambio, se recomienda mejor usar neumáticos
            con un caucho blando.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>Algunas sugerencias para escoger neumáticos en tu Quad</h2>
          <p>
            Existen una serie de recomendaciones que te aconsejamos seguir para
            que hagas una buena elección en el momento de escoger neumáticos
            para tu Quad:
          </p>
          <ul>
            <li>
              Elige siempre neumáticos de la medida especifica que marca el
              fabricante del vehículo.
            </li>
            <li>
              Comprueba que los índices de carga y velocidad se ajustan a las
              especificaciones de tu Quad.
            </li>
            <li>
              Analiza el uso que va a recibir el neumático y en función de ello
              escoge gomas más o menos duras.
            </li>
            <li>
              Cuando realices un cambio de neumáticos te aconsejamos también
              realizar un equilibrado. Al igual que sucede con los automóviles
              las ruedas de Quad también requieren de este mantenimiento básico.
            </li>
            <li>
              Regularmente comprueba las presiones de las ruedas. Este pequeño
              gesto te ayudará a alargar su vida útil y permitirá que tu Quad se
              adapte mejor a las condiciones del terreno.
            </li>
          </ul>
        </section>
      </article>
    </div>
  )
}

const LandingQuad = ({ ...props }: Props) => <LandingQuadEs {...props} />

export default LandingQuad
